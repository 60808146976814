//Get COIN_PACKAGE
export const GET_COIN_PACKAGE = "GET_COIN_PACKAGE";

//Insert COIN_PACKAGE
export const INSERT_COIN_PACKAGE = "INSERT_COIN_PACKAGE";

//Update COIN_PACKAGE
export const UPDATE_COIN_PACKAGE = "UPDATE_COIN_PACKAGE";

//Delete COIN_PACKAGE
export const DELETE_COIN_PACKAGE = "DELETE_COIN_PACKAGE";

//Insert Open Dialog
export const OPEN_COIN_PACKAGE_DIALOG = "OPEN_COIN_PACKAGE_DIALOG";

//Close Dialog
export const CLOSE_DIALOG = "CLOSE_DIALOG";

//toast
export const OPEN_COIN_PACKAGE_TOAST = "OPEN_COIN_PACKAGE_TOAST";
export const CLOSE_COIN_PACKAGE_TOAST = "CLOSE_COIN_PACKAGE_TOAST";
