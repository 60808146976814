import {
  GET_TRANSACTIONS,
} from "./transaction.type";

const initialState = {
  transactions: [],
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
};

const coinTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    //get history
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    default:
      return state;
  }
};

export default coinTransactionReducer;
