//Types
import {
  CLOSE_COIN_PACKAGE_TOAST,
  CLOSE_DIALOG,
  DELETE_COIN_PACKAGE,
  GET_COIN_PACKAGE,
  INSERT_COIN_PACKAGE,
  OPEN_COIN_PACKAGE_TOAST,
  OPEN_COIN_PACKAGE_DIALOG,
  UPDATE_COIN_PACKAGE,
} from "./package.type";

//Define InitialState
const initialState = {
  coinPackage: [],
  dialog: false,
  dialogData: null,
  toast: false,
  toastData: null,
  actionFor: null,
};

const coinPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get Category
    case GET_COIN_PACKAGE:
      return {
        ...state,
        coinPackage: action.payload,
      };

    //Insert category
    case INSERT_COIN_PACKAGE:
      const data = [...state.coinPackage];
      data.unshift(action.payload);
      return {
        ...state,
        coinPackage: data,
      };

    //Update category
    case UPDATE_COIN_PACKAGE:
      return {
        ...state,
        coinPackage: state.coinPackage.map((coinPackage) =>
          coinPackage._id === action.payload.id ? action.payload.data : coinPackage
        ),
      };

    //Delete category
    case DELETE_COIN_PACKAGE:
      return {
        ...state,
        coinPackage: state.coinPackage.filter((coinPackage) => coinPackage._id !== action.payload),
      };

    //Open Dialog
    case OPEN_COIN_PACKAGE_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Dialog
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    //Open Toast
    case OPEN_COIN_PACKAGE_TOAST:
      return {
        ...state,
        toast: true,
        toastData: action.payload.data || null,
        actionFor: action.payload.for || null,
      };
    case CLOSE_COIN_PACKAGE_TOAST:
      return {
        ...state,
        toast: false,
        toastData: null,
        actionFor: null,
      };
    default:
      return state;
  }
};

export default coinPackageReducer;
